import React from "react"
import SEO from "../components/seo"
const Ideck = () => (
  <>
    <SEO title="SpaceIt Investor Deck" description="Confidential" />
    <div style={{ flex: 1 }}>
      <iframe
        src="https://1drv.ms/p/c/f4368123d16b9e7b/UQN7nmvRI4E2IID0xgAAAAAAABnZydpos3wEeQ0"
        width="100%"
        allowFullScreen
        style={{ width: "100%", height: "100vh", border: "none" }}
      >
        This is an embedded{" "}
        <a target="_blank" href="https://office.com" rel="noreferrer">
          Microsoft Office
        </a>{" "}
        presentation, powered by{" "}
        <a target="_blank" href="https://office.com/webapps" rel="noreferrer">
          Office
        </a>
        .
      </iframe>
    </div>
  </>
)

export default Ideck
